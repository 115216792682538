import React from "react";
import bigLogo from "../../assets/bitluma logo mec/taza mec logo2.png";
import bigShadow from "../../assets/bitluma logo mec/Verevi luys.png";
import smallShadow from "../../assets/bitluma logo mec/oxak.png";
import "./Header.css";
import NavBar from "../NavBar/NavBar";
import Arrow from "../Arrow/Arrow";
import MovingText from "react-moving-text";

const Header = ({
    scrollTo,
    smoothScrollFC,
    menu,
    homePageRef,
    y,
    isScrolled,
}) => {
    return (
        <header ref={homePageRef}>
            <div className="backgroundLayer"></div>
            <div className="navContainer">
                <NavBar
                    smoothScrollFC={smoothScrollFC}
                    menu={menu}
                    props={isScrolled}
                    propY={y}
                />
                <img src={bigShadow} className="bigShadow" />
                <img src={smallShadow} className="smallShadow" />
                <div className="headerContent">
                    <div className="headerLeft">
                        <MovingText
                            type="slideInFromLeft"
                            duration="2000ms"
                            // delay="1s"
                            direction="normal"
                            timing="ease"
                            iteration="1"
                            fillMode="none"
                        >
                            <h1>THE FUTURE</h1>
                            <h2>IS IN YOUR HANDS</h2>
                        </MovingText>
                        <MovingText
                            type="slideInFromRight"
                            duration="2000ms"
                            direction="normal"
                            timing="ease"
                            iteration="1"
                            fillMode="none"
                        >
                            <h3>
                            Welcome to Bitluma, where innovation meets wisdom in the boundless realm of Web3.
                            </h3>
                        </MovingText>
                    </div>
                    <div className="headerRight">
                        <img src={bigLogo} className="bigLogo" />
                    </div>
                </div>
            </div>
            <Arrow scrollTo={scrollTo} />
        </header>
    );
};

export default Header;
