import React from "react";
import AllSlider from "./Slider";
import "./ThirdSection.css";
const ThirdSection = () => {
    return (
        <div>
            <div className="third_title">
                <div className="third_left">
                    <h4>GET TO KNOW</h4>
                    <h1>
                        <span>Our</span> Team
                    </h1>
                </div>
                <div className="third_right">
                    <p>
                        The BitLuma team consists of highly qualified,
                        experienced professionals who specialize in their work,
                        which guarantees that the project we have started will
                        achieve its goal.
                    </p>
                </div>
            </div>
            <div className="third_content">
                <AllSlider />
            </div>
        </div>
    );
};

export default ThirdSection;
