import React, { useEffect, useState, useRef } from "react";
import smallLogo from "../../assets/bitluma logo mec/bitluma puchur logo.png";
import "./NavBar.css";
import MenuIcon from "@mui/icons-material/Menu";
const NavBar = ({ smoothScrollFC, menu, props, propY }) => {
    // useState
    const [activeSection, setActiveSection] = useState(null);
    const [activeClass, setActiveClass] = useState(0);
    const [offset, setOffset] = useState(false);
    const [active, setActive] = useState(0);
    // useRef
    const observer = useRef(null);

    //useEffect
    useEffect(() => {
        //create new instance and pass a callback function
        observer.current = new IntersectionObserver((entries) => {
            const visibleSection = entries.find(
                (entry) => entry.isIntersecting
            )?.target;
            //Update state with the visible section ID
            if (visibleSection) {
                setActiveSection(visibleSection.id);
            }
        });

        //Get custom attribute data-section from all sections
        const sections = document.querySelectorAll("[data-section]");

        sections.forEach((section) => {
            observer.current.observe(section);
        });
        //Cleanup function to remove observer
        return () => {
            sections.forEach((section) => {
                observer.current.unobserve(section);
            });
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setActiveClass(propY);
        });
    }, [propY]);

    useEffect(() => {
        if (window.scrollY > 50) {
            setOffset(true);
        } else {
            setOffset(false);
        }
    }, [window.scrollY]);

    const changeActiveClass = (index) => {
        burger.current.classList.remove("activeBarOpen");

        setActiveClass(index);
    };

    // propY--;

    // const scrollSection = (i, e) => {
    //     console.log(e);
    //     setActive(i);
    //     smoothScrollFC(e.ref)
    // };
    const burger = useRef(null);
    const openBurger = () => {
        if (burger.current.classList.contains("activeBarOpen")) {
            burger.current.classList.remove("activeBarOpen");
        } else {
            burger.current.classList.add("activeBarOpen");
        }
    };
    return (
        <>
            <nav className={offset ? "isOffset" : "noOffset"}>
                <div className="left">
                    <img src={smallLogo} />
                </div>
                <div className="right">
                    <ul>
                        {menu.map((e, index) => {
                            return (
                                <li
                                    key={index}
                                    onClick={() => {
                                        changeActiveClass(index);
                                        smoothScrollFC(e.ref);
                                    }}
                                    className={
                                        activeClass === index ||
                                        (propY === index &&
                                            activeClass === index &&
                                            activeClass !== 0)
                                            ? "active"
                                            : ""
                                    }
                                >
                                    {e.name}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="menu" onClick={openBurger}>
                    <MenuIcon />
                </div>
            </nav>
            <div className="activeMenu">
                <ul ref={burger} className="activeBar">
                    {menu.map((e, index) => {
                        return (
                            <li
                                key={index}
                                onClick={() => {
                                    changeActiveClass(index);
                                    smoothScrollFC(e.ref);
                                }}
                                className={
                                    activeClass === index ||
                                    (propY === index &&
                                        activeClass === index &&
                                        activeClass !== 0)
                                        ? "active"
                                        : ""
                                }
                            >
                                {e.name}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
};

export default NavBar;
