import React from "react";
import TimelineObserver from "react-timeline-animation";
import "./FirstSection.css";
import { Timeline } from "./TimeLine";
import firtsImg from "../../assets/new Future 2/coins_main_page_v1_00000 (1).png";
const FirstSection = () => {
    return (
        <>
            <div className="first_title">
                <h1>Privileges of BitLuma</h1>
                <h3>
                    In the realm of Web3, Bitluma is guided by five core
                    principles—Fairness, Constructiveness, Efficiency, Security,
                    and Reliability. These principles are not just ideals;
                    they're our compass to unparalleled success.
                </h3>
            </div>
            <div className="first_content">
                <div className="first_left">
                    <TimelineObserver
                        initialColor="#e5e5e5"
                        fillColor="#00efc6"
                        handleObserve={(setObserver) => (
                            <Timeline
                                className="timeline"
                                setObserver={setObserver}
                            />
                        )}
                    />
                </div>
                <div className="first_right">
                    <img src={firtsImg} />
                </div>
            </div>
        </>
    );
};

export default FirstSection;
