import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import emailjs from "@emailjs/browser";
export default function HelperTextAligned() {
    const [nameInp, setNameInp] = React.useState("");
    const [emailInp, setEmailInp] = React.useState("");
    const [messageInp, setMessageInp] = React.useState("");
    const [isOk, setIsOk] = React.useState(false);
    const form = React.useRef(null);
    const popUp = React.useRef(null);
    const sendEmail = (e) => {
        setIsOk(true);
        e.preventDefault();
        emailjs
            .sendForm(
                "service_m3999bq",
                "template_0m5ya0j",
                form.current,
                "lWNpNxbAf_2cAbJcT"
            )
            .then(() => {
                popUp.current.classList.add("pop_active");
                setTimeout(() => {
                    popUp.current.classList.remove("pop_active");
                }, 3000);
                setTimeout(() => {
                    setIsOk(false);
                }, 3500);
            });
        setNameInp("");
        setEmailInp("");
        setMessageInp("");
    };

    return (
        <form onSubmit={sendEmail} ref={form}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    "& > :not(style)": { m: 1 },
                    flexDirection: "column",
                }}
            >
                <TextField
                    id="demo-helper-text-aligned"
                    label="Name"
                    fullWidth
                    value={nameInp}
                    onChange={(e) => setNameInp(e.target.value)}
                    autoComplete="off"
                    required
                    variant="filled"
                    name="user_name"
                />
                <TextField
                    id="demo-helper-text-aligned-no-helper"
                    label="Email"
                    fullWidth
                    value={emailInp}
                    onChange={(e) => setEmailInp(e.target.value)}
                    autoComplete="off"
                    required
                    variant="filled"
                    name="user_email"
                />
                <textarea
                    autoComplete="off"
                    placeholder="Your message..."
                    value={messageInp}
                    onChange={(e) => setMessageInp(e.target.value)}
                    required
                    name="message"
                >
                </textarea>
                {isOk ? (
                    <div ref={popUp} className="popUp">
                        <h4>Your message has been sent successfully!!!</h4>
                    </div>
                ) : (
                    ""
                )}
                <button>Send Your Message...</button>
            </Box>
        </form>
    );
}

// const blue = {
//     100: "#DAECFF",
//     200: "#b6daff",
//     400: "#3399FF",
//     500: "#007FFF",
//     600: "#0072E5",
//     900: "#003A75",
// };

// const grey = {
//     50: "#F3F6F9",
//     100: "#E5EAF2",
//     200: "#DAE2ED",
//     300: "#C7D0DD",
//     400: "#B0B8C4",
//     500: "#9DA8B7",
//     600: "#6B7A90",
//     700: "#434D5B",
//     800: "#303740",
//     900: "#1C2025",
// };
