import React from "react";
import bitLumaImg from "../../assets/new Future 2/Bitluma_charts (1).png";
import HelperTextAligned from "./Field";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./FourSection.css";
const FourSection = () => {
    return (
        <div>
            <div className="four_title">
                <h1>Contact Us</h1>
                <h3>
                    IF YOU HAVE ANY QUESTIONS, WRITE TO US AND WE WILL BE HAPPY
                    TO ANSWER THEM.
                </h3>
            </div>
            <div className="four_content">
                <div className="four_left">
                    <div className="four_login">
                        <HelperTextAligned />
                    </div>
                    <div className="moreInfo">
                        <a target="_blank" href="https://twitter.com/bitluma">
                            <TwitterIcon />
                        </a>
                        <a
                            target="_blank"
                            href="https://www.youtube.com/@bitluma6442"
                        >
                            <YouTubeIcon />
                        </a>
                        <a
                            target="_blank"
                            href="https://www.linkedin.com/company/bitluma/"
                        >
                            <LinkedInIcon />
                        </a>
                        <a
                            target="_blank"
                            href="https://www.facebook.com/Bitluma/"
                        >
                            <FacebookIcon />
                        </a>
                        <a
                            target="_blank"
                            href="https://www.instagram.com/bitluma/"
                        >
                            <InstagramIcon />
                        </a>
                    </div>
                </div>
                <div className="four_right">
                    <img src={bitLumaImg} />
                </div>
            </div>
        </div>
    );
};

export default FourSection;
