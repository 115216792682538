import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";

export const sample = [
    ["anonymous", "IT"],
    ["anonymous", "IT"],
    ["anonymous", "IT"],
    ["anonymous", "IT"],
    ["anonymous", "Culture"],
    ["anonymous", "Culture"],
    ["anonymous", "Science"],
    ["anonymous", "Science"],
    ["anonymous", "Politics"],
    ["anonymous", "Military"],
    ["anonymous", "Law"],
    ["anonymous", "Spirituality"]
];

function createData(id, proffesion, name) {
    return { name, proffesion, id };
}

const columns = [
    {
        width: 200,
        label: "Name/Second Name",
        dataKey: "proffesion",
    },
    {
        width: 120,
        label: "Sphere",
        dataKey: "name",
        numeric: true,
    },
    {
        width: 120,
        label: "Seat on the advice",
        dataKey: "id",
        numeric: true,
    },
];

const rows = Array.from({ length: sample.length }, (_, index) => {
    const randomSelection = sample[index];
    return createData(index + 1, ...randomSelection);
});

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table
            {...props}
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
        />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => (
        <TableBody {...props} ref={ref} />
    )),
};

function fixedHeaderContent() {
    return (
        <TableRow>
            {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align={column.numeric || false ? "right" : "left"}
                    style={{ width: column.width }}
                    sx={{
                        backgroundColor: "#121212",
                        color: "#c2faf3",
                    }}
                >
                    {column.label}
                </TableCell>
            ))}
        </TableRow>
    );
}

function rowContent(_index, row) {
    return (
        <React.Fragment>
            {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    align={column.numeric || false ? "right" : "left"}
                    style={{
                        backgroundColor: "#1E1E1E",
                        color: "whitesmoke",
                    }}
                >
                    {row[column.dataKey]}
                </TableCell>
            ))}
        </React.Fragment>
    );
}

export default function ReactVirtualizedTable() {
    return (
        <Paper style={{ height: 400, width: "100%" }}>
            <TableVirtuoso
                data={rows}
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
            />
        </Paper>
    );
}
