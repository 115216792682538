import React from "react";
import "./SecondSection.css";
import StraightAnglePieChart from "./Chart";
import ReactVirtualizedTable from "./Table";

const SecondSection = () => {
    return (
        <>
            <div className="second_title">
                <h1>Advice</h1>
                <h3>
                    The Wisdom of 101 Minds The Council of Sages, a conclave of
                    101 luminaries, is the compass guiding Bitluma's journey.
                    Each sage hails from diverse fields—IT, science, culture,
                    spirituality, law, military, politics, and
                    beyond—culminating in a collective wisdom that shapes the
                    destiny of Bitluma.
                </h3>
            </div>
            <div className="second_content">
                <div className="second_left">
                    <StraightAnglePieChart />
                </div>
                <div className="second_right">
                    <ReactVirtualizedTable />
                </div>
            </div>
        </>
    );
};

export default SecondSection;
