import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import Stack from "@mui/material/Stack";
import { sample } from "./Table";

const data = [
    { label: "IT", value: 0 },
    { label: "Politics", value: 0 },
    { label: "Culture", value: 0 },
    { label: "Science", value: 0 },
    { label: "Spirituality", value: 0 },
    { label: "Law", value: 0 },
    { label: "Military", value: 0 },
];
sample.forEach((e) => {
    data.forEach((de) => {
        if (e[1] === de.label) {
            de.value = de.value + 1;
        }
    });
});
export default function StraightAnglePieChart() {
    return (
        <Stack direction="row">
            <PieChart
                series={[
                    {
                        paddingAngle: 5,
                        innerRadius: 200,
                        // outerRadius: 80,
                        data,
                    },
                ]}
                // margin={{ right: 5 }}
                width={600}
                height={500}
                legend={{ hidden: true }}
                colors={["#00efc6"]}
            />
        </Stack>
    );
}
