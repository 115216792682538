import { useEffect, useRef, useState } from "react";
import "./App.css";
import Container from "./components/Container/Container";
import Header from "./components/Header/Header";
import Arrow from "./components/Arrow/Arrow";
import { motion, useScroll } from "framer-motion";
import ZeroSection from "./components/ZeroSection/ZeroSection";
import FirstSection from "./components/FirstSection/FirstSection";
import SecondSection from "./components/SecondSection/SecondSection";
import ThirdSection from "./components/ThirdSection/ThirdSection";
import FourSection from "./components/FourSection/FourSection";
const App = () => {
    const { scrollYProgress } = useScroll();

    const [isScrolled, setScroll] = useState(false);
    const [y, setY] = useState();
    const [offset, setOffset] = useState(0);
    // create ref to scroll
    const homeRef = useRef(null);
    //about ref
    const targetAudienceRef = useRef(null);
    // why us
    const whyUsRef = useRef(null);
    // advince
    const program = useRef(null);

    // team
    const teamRef = useRef(null);

    //contact
    const faqRef = useRef(null);
    const contactUs = useRef(null);
    //menu for nav bar
    const menu = [
        { id: 0, name: "HOME", ref: homeRef },
        { id: 1, name: "ABOUT BITLUMA", ref: targetAudienceRef },
        { id: 2, name: "PRIVILEGES", ref: whyUsRef },
        { id: 3, name: "ADVICE", ref: program },
        { id: 4, name: "OUR TEAM", ref: teamRef },
        { id: 5, name: "CONTACT US", ref: faqRef },
    ];
    // Scrolls Functions
    const scrollTo = () => {
        targetAudienceRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const smoothScrollFC = (name) => {
        name.current.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset);
        };
    }, []);

    // main function scrolling elements
    const position = () => {
        const home = homeRef.current?.offsetTop;
        const target = targetAudienceRef.current?.offsetTop - 200;
        const whyUs = whyUsRef.current?.offsetTop - 200;
        const programSec = program.current?.offsetTop - 200;
        const team = teamRef.current?.offsetTop - 200;
        const faq = faqRef.current?.offsetTop - 200;
        const contactUsSec = contactUs.current?.offsetTop - 200;
        let arr = [];
        if (window.scrollY < target) {
            arr = [];
            arr = menu.filter((val) => val.ref === homeRef);
            setScroll(true);
            setY(arr[0]?.id);
        } else if (window.scrollY >= target && window.scrollY < whyUs) {
            arr = [];
            arr = menu.filter((val) => val.ref === targetAudienceRef);
            setScroll(true);
            setY(arr[0]?.id);
        } else if (window.scrollY >= whyUs && window.scrollY < programSec) {
            arr = [];
            arr = menu.filter((val) => val.ref === whyUsRef);
            setScroll(true);
            setY(arr[0]?.id);
        } else if (window.scrollY >= programSec && window.scrollY < team) {
            arr = [];
            arr = menu.filter((val) => val.ref === program);
            setScroll(true);
            setY(arr[0]?.id);
        } else if (window.scrollY >= team && window.scrollY < faq) {
            arr = [];
            arr = menu.filter((val) => val.ref === teamRef);
            setScroll(true);
            setY(arr[0]?.id);
        } else if (window.scrollY >= faq) {
            arr = [];
            arr = menu.filter((val) => val.ref === faqRef);
            setScroll(true);
            setY(arr[0]?.id);
        } else if (window.scrollY >= contactUsSec) {
            arr = [];
            arr = menu.filter((val) => val.ref === contactUs);
            setScroll(true);
            setY(arr[0]?.id);
        } else {
            /// ste kod grvi vor chtraqi
        }
    };

    useEffect(() => {
        position();
        window.addEventListener("scroll", position);
    });

    return (
        <>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header
                menu={menu}
                smoothScrollFC={smoothScrollFC}
                scrollTo={scrollTo}
                isScrolled={isScrolled}
                y={y}
                homePageRef={homeRef}
            />
            <Container>
                <div ref={targetAudienceRef} className="nextSection zero">
                    <ZeroSection />
                </div>
                <div ref={whyUsRef} className="nextSection first">
                    <FirstSection />
                </div>
                <div ref={program} className="nextSection second">
                    <SecondSection />
                </div>
                <div ref={teamRef} className="nextSection third">
                    <ThirdSection />
                </div>
                <div ref={faqRef} className="nextSection forth">
                    <FourSection />
                </div>
            </Container>
            {offset > 600 ? <Arrow down="down" up="up" /> : ""}
        </>
    );
};

export default App;
