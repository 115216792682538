import React from "react";
import leftBitluma from "../../assets/new Future 2/1 (2) (1).png";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import MovingText from "react-moving-text";
import "./ZeroSection.css";
import whitepaper from "../../Bitluma`s White Paper.pdf";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
const ZeroSection = () => {
    return (
        <>
            <div className="zero_title">
                <h1>What is BitLuma?</h1>
            </div>

            <div className="zero_content">
                <div className="zero_left">
                    <img src={leftBitluma} />
                </div>
                <div className="zero_right">
                    <MovingText
                        type="slideInFromLeft"
                        duration="2000ms"
                        // delay="1s"
                        direction="normal"
                        timing="ease"
                        iteration="1"
                        fillMode="none"
                    >
                        <TimelineItem>
                            <TimelineOppositeContent
                                style={{
                                    color: "#c2faf3",
                                    fontFamily: "Montserrat",
                                }}
                            >
                                About BitLuma.
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot
                                    variant="outlined"
                                    color="primary"
                                />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 100,
                                }}
                            >
                                Discover the essence of Bitluma. Learn about our
                                mission, values, and the dedicated team of
                                professionals driving the vision forward.
                                Understand the strategic investments that
                                position Bitluma as a pioneer in the Web3
                                landscape.
                            </TimelineContent>
                        </TimelineItem>
                    </MovingText>
                    <MovingText
                        type="slideInFromRight"
                        duration="2000ms"
                        // delay="1s"
                        direction="normal"
                        timing="ease"
                        iteration="1"
                        fillMode="none"
                    >
                        <TimelineItem>
                            <TimelineOppositeContent
                                style={{
                                    color: "#c2faf3",
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Technology
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot
                                    variant="outlined"
                                    color="primary"
                                />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 100,
                                }}
                            >
                                Delve into the technological foundations of
                                Bitluma. Uncover the Hashgraph consensus
                                mechanism and the seamless integration with the
                                Hedera Token Service (HTS). Explore how these
                                technologies redefine decentralization,
                                security, and efficiency.
                            </TimelineContent>
                        </TimelineItem>
                    </MovingText>
                    <MovingText
                        type="slideInFromLeft"
                        duration="2000ms"
                        direction="normal"
                        timing="ease"
                        iteration="1"
                        fillMode="none"
                    >
                        <TimelineItem>
                            <TimelineOppositeContent
                                style={{
                                    color: "#c2faf3",
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Download The White Paper
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot
                                    variant="outlined"
                                    color="primary"
                                />
                            </TimelineSeparator>
                            <TimelineContent
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 100,
                                }}
                            >
                                <a
                                    href={whitepaper}
                                    download="White Paper BitLuma"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <SimCardDownloadIcon
                                        color="info"
                                        fontSize="large"
                                    />
                                </a>
                            </TimelineContent>
                        </TimelineItem>
                    </MovingText>
                </div>
            </div>
        </>
    );
};

export default ZeroSection;
