import React from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const Card = ({ item }) => {
    return (
        <div className="cards">
            <img src={item.img} />
            <h3>{item.name}</h3>
            <h3 className="position">{item.position}</h3>
            <a target="_blank" href={item?.url1_fb}>
                {item?.url1_fb ? <FacebookOutlinedIcon /> : ""}
            </a>
            <a target="_blank" href={item.url1}>
                {item?.url1 ? <LinkedInIcon /> : ""}
            </a>
            <a target="_blank" href={item.url2}>
                <InstagramIcon />
            </a>
        </div>
    );
};

export default Card;
