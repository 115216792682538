import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./Slider.css";

// import required modules
import {
    EffectCoverflow,
    Pagination,
    Autoplay,
    Navigation,
} from "swiper/modules";
import Rob from "../../assets/team/rob.jpg";
import Gor from "../../assets/team/gor.jpg";
import Mayo from "../../assets/team/mayo.jpg";
import Narek from "../../assets/team/narek.jpg";
import Vahe from "../../assets/team/Vahe.jpg";
import Card from "./Card";
let sliderArray = [
    {
        id: 1,
        name: "Mayis Khudoyan",
        position: "Marketing Maneger",
        img: Mayo,
        url1: "https://www.linkedin.com/in/mayis-khudoyan-35a736198/",
        url2: "https://www.instagram.com/kayfaration/",
    },
    {
        id: 2,
        name: "Narek Hakobyan",
        position: "Web3 Developer",
        img: Narek,
        url1: "https://www.linkedin.com/in/narek-hakobyan-165772222/",
        url2: "https://www.instagram.com/narekhakobyan_btl/?igsh=MWdlY3RjM3lwemlhdQ%3D%3D",
    },
    {
        id: 3,
        name: "Gor Harutyunyan",
        position: "Front-End Developer",
        img: Gor,
        url1: "https://www.linkedin.com/in/gor-harutyunyan888/",
        url2: "https://www.instagram.com/gorr88.8/",
    },
    {
        id: 4,
        name: "Robert Babayan",
        position: "Media Production",
        img: Rob,
        url1_fb: "https://www.facebook.com/mr.babaian",
        url2: "https://www.instagram.com/mr.babaian/",
    },
    {
        id: 4,
        name: "Vahe Mnatsakanyan",
        position: "FullStack Developer",
        img: Vahe,
        url1: "https://www.linkedin.com/in/vahe-mnatsakanyan-665157202/",
        url2: "https://www.instagram.com/_v____m__/?igsh=MTBiOHBmNjVwcTI0MQ%3D%3D",
    },
];
export default function AllSlider() {
    return (
        <>
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                navigation={true}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                pagination={false}
                modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
                className="mySwiper"
            >
                {sliderArray.map((e) => {
                    return (
                        <SwiperSlide>
                            <Card item={e} key={e.id} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
}
