import React from "react";
import "./Arrow.css";

const Arrow = ({ scrollTo, down, up }) => {
    const toUp = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <div onClick={up ? toUp : scrollTo} className={`arrow ${down}`}>
            <span onClick={scrollTo}></span>
            <span onClick={scrollTo}></span>
            <span onClick={scrollTo}></span>
        </div>
    );
};

export default Arrow;
