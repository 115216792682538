import React, { useEffect, useRef, useState } from "react";
import "./TimeLine.css";
export const Timeline = ({ setObserver }) => {
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [message4, setMessage4] = useState("");
    const [message5, setMessage5] = useState("");

    const timeline1 = useRef(null);
    const timeline2 = useRef(null);
    const timeline3 = useRef(null);
    const timeline4 = useRef(null);
    const timeline5 = useRef(null);
    const circle1 = useRef(null);
    const circle2 = useRef(null);
    const circle3 = useRef(null);
    const circle4 = useRef(null);
    const circle5 = useRef(null);

    const someCallback = () => {
        setMessage1("Fairness");
    };

    const someCallback2 = () => {
        setMessage2("Constructiveness");
    };

    const someCallback3 = () => {
        setMessage3("Efficiency");
    };
    const someCallback4 = () => {
        setMessage4("Security");
    };
    const someCallback5 = () => {
        setMessage5("Reliability");
    };

    useEffect(() => {
        setObserver(timeline1.current);
        setObserver(timeline2.current);
        setObserver(timeline3.current);
        setObserver(timeline4.current);
        setObserver(timeline5.current);
        setObserver(circle1.current, someCallback);
        setObserver(circle2.current, someCallback2);
        setObserver(circle3.current, someCallback3);
        setObserver(circle4.current, someCallback4);
        setObserver(circle5.current, someCallback5);
    }, []);

    return (
        <div className="wrapper">
            <div id="timeline1" ref={timeline1} className="timeline" />
            <div className="circleWrapper">
                <div id="circle1" ref={circle1} className="circle">
                    1
                </div>
                <div className="message">{message1}</div>
            </div>
            <div id="timeline2" ref={timeline2} className="timeline" />
            <div className="circleWrapper">
                <div id="circle2" ref={circle2} className="circle">
                    2
                </div>
                <div className="message">{message2}</div>
            </div>
            <div id="timeline3" ref={timeline3} className="timeline" />
            <div className="circleWrapper">
                <div id="circle3" ref={circle3} className="circle">
                    3
                </div>
                <div className="message">{message3}</div>
            </div>
            <div id="timeline4" ref={timeline4} className="timeline" />
            <div className="circleWrapper">
                <div id="circle4" ref={circle4} className="circle">
                    4
                </div>
                <div className="message">{message4}</div>
            </div>
            <div id="timeline5" ref={timeline5} className="timeline" />
            <div className="circleWrapper">
                <div id="circle5" ref={circle5} className="circle">
                    5
                </div>
                <div className="message">{message5}</div>
            </div>
        </div>
    );
};
